<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear
							style="width: 180px;">
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="20">
						<a-button type="primary" @click="onAdd" v-permission="['coupon_preferential_rules_add']">新增政策</a-button>
					</a-col>
					<a-col :span="4" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 10px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list"
					:scroll="{ x: 1000 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'timeList'">
							<span v-for="item in record.timeList" :key="item" style="margin-right: 5px;">
								<span v-if="item === 1">周一</span>
								<span v-if="item === 2">周二</span>
								<span v-if="item === 3">周三</span>
								<span v-if="item === 4">周四</span>
								<span v-if="item === 5">周五</span>
								<span v-if="item === 6">周六</span>
								<span v-if="item === 7">周日</span>
							</span>
							<div v-if="!record.timeList">周日 周一 周二 周三 周四 周五 周六</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSee(record)">
											<a-menu-item>
												查看
											</a-menu-item>
										</div>
										<div v-permission="['coupon_preferential_rules_submit']"
											@click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>

		</a-spin>
	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		getPricePolicyList,
		deletePricePolicy,
		updatePricePolicy,
		getCinemaAllList
	} from '@/service/modules/cinema.js';
	import {
		getUnionCardPolicyList,
		updateUnionCardPolicy
	} from "@/service/modules/coupon";
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				loading: false,
				cinemaAllList: [],
				formState: {},
				searchData: {},
				showModal: false,
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '会员日',
					key: 'timeList'
				}, {
					title: '每日限购',
					dataIndex: 'cardScheduleLimitNum',
				}, {
					title: '场次限购',
					dataIndex: 'cardScheduleTicketLimitNum'
				}, {
					title: '操作',
					key: 'action',
					width: 150,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isSee: false,
				isEdit: false,
				id: 0
			}
		},
		created() {
			this.onSearch();
			this.getAllCinemaList()
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getUnionCardPolicyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						let timeList = [];
						ret.data.list.forEach(info => {
							if (info.timeList) {
								info.timeList = JSON.parse(info.timeList);
								info.timeList.forEach(item => {
									item.week = item.week.split(',').map(item => Number(item));
									timeList = [...timeList, ...item.week];
								});
							}
							timeList = [...new Set(timeList)];
							info.timeList = timeList;
						})

						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaList() {
				this.cinemaAllList = [];
				let ret = await getCinemaAllList({})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data;
				}
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.isSee = false;
				this.showModal = true;
			},
			onSee(item) {
				this.id = item.id;
				this.isEdit = true;
				this.isSee = true;
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.isSee = false;
				this.showModal = true;
			},
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>